<template>
  <div class="question">
    <h1 class="question__title" v-html="data.question" />
    <div class="guess" v-if="isOpenGuessQuestion">
      <input
        class="guess__input"
        type="text"
        name="choice"
        v-model="openGuessAnswer"
        placeholder="Ex. $10,000"
      />
    </div>
    <div class="choices" v-else>
      <div
        class="choices__item"
        v-for="(choice, key) in data.choices"
        :key="key"
      >
        <img
          v-if="data.rightAnswer.includes(key) && isAnswerShown"
          :src="correctIcon"
          alt="Correct answer"
          class="choices__icon"
        />
        <img
          v-else-if="
            !data.rightAnswer.includes(key) &&
            answer.includes(key) &&
            isAnswerShown
          "
          :src="wrongIcon"
          alt="Wrong answer"
          class="choices__icon"
        />
        <input
          class="choices__input"
          :id="key"
          :type="data.type"
          name="choice"
          v-model="answer"
          :value="key"
        />
        <label class="choices__label" :for="key" v-html="choice" />
      </div>
    </div>
    <div class="question__footer">
      <SubmitButton
        v-if="buttonState === 'submit' && (answer || openGuessAnswer)"
        @action="submitHandler"
        text="Submit"
        variant="contained"
      />
      <SubmitButton
        v-else-if="buttonState === 'next' && !openGuessAnswer"
        @action="$emit('next-page')"
        text="Next"
        variant="contained"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitButton from "./SubmitButton";
import correctIcon from "../assets/images/icons/correct.svg";
import wrongIcon from "../assets/images/icons/wrong.svg";

export default {
  name: "QuestionItem",
  components: {
    SubmitButton,
  },
  props: ["data", "isOpenGuessQuestion"],
  data() {
    return {
      answer: this.data.type === "radio" ? "" : [],
      isAnswerShown: false,
      buttonState: "submit",
      correctIcon,
      wrongIcon,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    openGuessAnswer: {
      get() {
        return this.$store.getters.openGuessAnswer;
      },
      set(value) {
        this.$store.dispatch("setOpenGuessAnswer", value);
      },
    },
  },
  methods: {
    submitHandler() {
      if (this.isOpenGuessQuestion) {
        this.$emit("send-openguess", this.openGuessAnswer);
      } else {
        this.isAnswerShown = true;
        this.buttonState = "next";
        this.$emit("submit-answer", this.answer);
      }
    },
  },
};
</script>

<style lang="scss">
.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 116.5rem;
  margin-inline: auto;
  padding-inline: 1rem;

  text-align: center;
}

.question__title {
  font-family: "RosarioBold", sans-serif;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 1.2;
}

.question__footer {
  margin-top: 9.2rem;
  max-width: 28.1rem;
  width: 100%;
}

.guess {
  margin: 12rem 0 5.8rem;
  max-width: 30rem;
  width: 100%;
}

.guess__input {
  padding-bottom: 2.1rem;
  border: none;
  border-bottom: 2px solid #ffffff;
  outline: none;
  width: 100%;

  font-weight: 400;
  font-family: "RobotoRegular", sans-serif;
  font-size: 3.6rem;
  text-align: center;

  background: none;
  color: #ffffff;

  &::placeholder {
    color: #999999;
  }
}

.choices {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2.3rem;
  grid-row-gap: 1.8rem;

  margin-top: 6rem;
  width: 100%;

  filter: drop-shadow(0px 22px 33px rgba(16, 17, 63, 0.17));
}

.choices__item {
  position: relative;
}

.choices__input {
  display: none;

  &:checked + .choices__label {
    box-shadow: 0 5px 17px rgba(0, 0, 0, 0.15);

    background: rgba(89, 103, 116, 0.85);
  }
}

.choices__label {
  display: block;

  height: 100%;
  padding: 2.5rem 10rem;
  user-select: none;
  border-radius: 1rem;

  font-weight: 700;
  font-family: "RosarioBold", sans-serif;
  font-size: 2.1rem;
  text-align: center;

  background: rgba(89, 103, 116, 0.47);
  color: #ffffff;
  cursor: pointer;

  transition: background 0.2s linear;

  &:hover {
    background: rgba(89, 103, 116, 0.85);
  }
}

.choices__icon {
  position: absolute;
  top: 50%;
  left: 3rem;
  transform: translate(0, -50%);
}

@media (max-width: 1440px) {
  .question__title {
    font-size: 6rem;
  }

  .choices__label {
    font-size: 2.6rem;
  }

  .guess {
    max-width: 37.5rem;
  }

  .guess__input {
    font-size: 4.5rem;
  }
}

@media (max-width: 1024px) {
  .question__title {
    font-size: 4rem;
  }
}

@media (max-width: 991px) {
  .question__title {
    font-size: 2.4rem;
  }

  .question__footer {
    margin-top: 2rem;
    max-width: 16rem;
  }

  .guess {
    margin: 2rem 0 4rem;
  }

  .guess__input {
    padding: 1.5rem 5rem;

    font-size: 1.8rem;
  }

  .choices {
    grid-column-gap: 1rem;
    grid-row-gap: 0.8rem;

    margin-top: 2rem;
  }

  .choices__label {
    padding: 1.5rem 10rem;

    font-size: 1.6rem;
  }

  .choices__icon {
    width: 3.4rem;
    height: 1.9rem;
  }
}
</style>
