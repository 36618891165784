<template>
  <div class="wheel">
    <img class="category__image" :src="emptyWheel" alt="Chosen category" />
    <img
      v-for="(fragment, i) in pieFragments"
      :key="i"
      v-show="chosenCategoryIndexes.indexOf(i + 1) !== -1"
      class="fragment"
      :class="fragment['color']"
      :src="fragment['src']"
      alt="Fragment"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import emptyWheel from "@/assets/images/empty-wheel.svg";
import purple from "@/assets/images/icons/purple.svg";
import red from "@/assets/images/icons/red.svg";
import orange from "@/assets/images/icons/orange.svg";
import yellow from "@/assets/images/icons/yellow.svg";
import green from "@/assets/images/icons/green.svg";
import blue from "@/assets/images/icons/blue.svg";

export default {
  name: "SmallWheel",
  computed: {
    ...mapGetters(["chosenCategoryIndexes"]),
  },
  data() {
    return {
      emptyWheel,
      pieFragments: [
        {
          color: "blue",
          src: blue,
        },
        {
          color: "green",
          src: green,
        },
        {
          color: "yellow",
          src: yellow,
        },
        {
          color: "orange",
          src: orange,
        },
        {
          color: "red",
          src: red,
        },
        {
          color: "purple",
          src: purple,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.wheel {
  position: relative;
}

.fragment {
  position: absolute;
  z-index: 2;
}

.blue {
  bottom: 12px;
  left: 22px;
}

.purple {
  top: 48px;
  left: 12px;
}

.red {
  top: 12px;
  left: 22px;
}

.orange {
  top: 12px;
  right: 22px;
}

.yellow {
  top: 48px;
  right: 12px;
}

.green {
  bottom: 12px;
  right: 22px;
}

@media (max-width: 991px) {
  .wheel > img {
    max-width: 12.5rem;
  }

  .blue {
    bottom: 7.4px;
    left: 14px;

    width: 4.4rem;
  }

  .purple {
    top: 30px;
    left: 7.4px;

    width: 4.9rem;
  }

  .red {
    top: 7.4px;
    left: 14px;

    width: 4.4rem;
  }

  .orange {
    top: 7.4px;
    right: 14px;

    width: 4.4rem;
  }

  .yellow {
    top: 30px;
    right: 7.4px;

    width: 4.9rem;
  }

  .green {
    bottom: 7.4px;
    right: 14px;

    width: 4.4rem;
  }
}
</style>
