<template>
  <div ref="category" class="category">
    <div class="category__wheel">
      <SmallWheel />
    </div>
    <div class="category__content">
      <h2 class="category__text">{{ data.category }}</h2>
    </div>
  </div>
</template>

<script>
import SmallWheel from "./SmallWheel";

export default {
  name: "CategoryItem",
  props: {
    data: {
      type: Object,
    },
  },
  components: { SmallWheel },
  mounted() {
    this.$refs.category.style.background = this.data.gradient;

    if (this.data.id === 3) {
      this.$refs.category.style.color = "#32313C";
    }
  },
};
</script>

<style scoped lang="scss">
.category {
  position: relative;

  max-width: 62.8rem;
  width: 90%;
  height: 11rem;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.25);
}

.category__wheel {
  position: absolute;
  top: -2.5rem;
  left: 4.6rem;
  z-index: 1;
}

.category__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  height: 100%;
  padding-left: 23rem;

  text-align: left;
  font-weight: 700;
}

.category__text {
  font-size: 3.6rem;
}

@media (max-width: 1440px) {
  .category {
    max-width: 78.5rem;
    height: 13.75rem;
  }

  .category__wheel {
    top: -3.5rem;
  }

  .category__content {
    padding-left: 30rem;
  }

  .category__text {
    font-size: 4.5rem;
  }
}

@media (max-width: 991px) {
  .category {
    height: 7rem;
    max-width: 50rem;
  }

  .category__wheel {
    top: -3rem;
    left: 1.6rem;
  }

  .category__content {
    padding-left: 18rem;
  }

  .category__text {
    font-size: 2rem;
  }
}

@media (max-width: 668px) {
  .category {
    height: 6rem;
    max-width: 40rem;
  }

  .category__content {
    padding-left: 15rem;
  }
}
</style>
