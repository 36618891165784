<template>
  <main class="questions">
    <CategoryItem :data="currentCategory" />
    <section class="questions__content">
      <QuestionItem
        :data="currentQuestion"
        :isOpenGuessQuestion="isOpenGuessQuestion"
        @submit-answer="submitAnswerHandler"
        @send-openguess="submitOpenGuessHandler"
        @next-page="nextPageHandler"
      />
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import QuestionItem from "@/components/QuestionItem";
import CategoryItem from "@/components/CategoryItem";
import routeNames from "@/router/routeNames";

export default {
  name: "Question",
  components: { CategoryItem, QuestionItem },
  computed: {
    ...mapGetters([
      "currentQuestion",
      "currentCategory",
      "chosenCategoryIndexes",
      "user",
    ]),
    isOpenGuessQuestion() {
      return this.currentQuestion.categoryId === 6;
    },
  },
  data() {
    return {
      isCorrectAnswer: false,
    };
  },
  methods: {
    async submitAnswerHandler(answer) {
      await this.$store.dispatch("setAnswer", answer);

      if (typeof answer === "string") {
        if (answer === this.currentQuestion.rightAnswer[0]) {
          await this.$store.dispatch("increaseScore", 1);
        }
      } else {
        answer.forEach((answer) => {
          this.currentQuestion.rightAnswer.includes(answer) &&
            this.$store.dispatch("increaseScore", 1);
        });
      }
    },
    async submitOpenGuessHandler(answer) {
      const userId = localStorage.getItem("userId");
      await this.$store.dispatch("setAnswer", +answer);
      await this.$store.dispatch("addOpenGuessAnswer", {
        userId,
        answer: +answer,
        name: this.user.name,
      });
      await this.$router.push(routeNames.final);
    },
    nextPageHandler() {
      this.$router.push(routeNames.selection);
    },
  },
};
</script>

<style scoped lang="scss">
.questions {
  padding-top: 10.5rem;
  padding-bottom: 2rem;
  overflow: hidden;
}

.questions__content {
  margin-top: 10rem;
}

@media (max-width: 1180px) {
  .questions {
    padding-top: 6rem;
  }

  .questions__content {
    margin-top: 6rem;
  }
}

@media (max-width: 991px) {
  .questions {
    padding-top: 4rem;
  }
}

@media (max-width: 668px) {
  .questions {
    padding-top: 3rem;
  }
}
</style>
